import React from 'react';
import { Link } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';

import NlIcon from 'icons/locales/nl.svg';
import DeIcon from 'icons/locales/de.svg';
import { Container } from './localeMenu.css';

// const localeIcon = {
//   nl: <NlIcon height="16px" style={{ paddingRight: '8px' }} />,
//   de: <DeIcon height="16px" style={{ paddingRight: '8px' }} />,
// };

const LocaleMenu = () => (
  <StaticQuery
    query={graphql`
      query {
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={data => {
      const parsedData = JSON.parse(data.buroBorkSite.field);

      if (parsedData.locales.length > 1) {
        return (
          <Container>
            <li>
              <NlIcon height="16px" style={{ paddingRight: '8px' }} />
              <Link to={'/'}>NL</Link>
            </li>
            <li>
              <DeIcon height="16px" style={{ paddingRight: '8px' }} />
              <Link to={'/de'}>DE</Link>
            </li>
          </Container>
        );
      } else {
        return <div />;
      }
    }}
  />
);

export default LocaleMenu;
