import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { mainColor, pageWidth } from 'constants/theme';

export const Container = styled.footer`
  max-width: ${pageWidth};
  margin: 0 auto;
  display: flex;
  background: #fff;
  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const Column = styled.div`
  flex: 1 1;
  padding: 16px 8px;
  h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    /* color: ${mainColor} */
  }
  ul {
    padding: 16px 0;
    li {
      padding: 8px 0;
      a {
        padding: 8px 0;
        color: #000;
        text-decoration: none;
        position: relative;
        &:before {
          transition: 0.2s all ease-in;
          position: absolute;
          content: '';
          height: 1px;
          width: 20px;
          bottom: 0;
          left: 0;          
          background: ${props => props.theme.primaryColor};

        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const Copyright = styled.div`
  font-size: 1.2rem;
  border-top: 1px solid ${props => props.theme.primaryColor};
  max-width: ${pageWidth};
  margin: 0 auto;
  padding: 16px;
  display: flex;
  color: rgba(0, 0, 0, 0.75);
  justify-content: space-between;
  align-content: center;
  div {
    display: flex;
    align-content: center;
  }
  span,
  a {
    margin-left: 4px;
  }
`;

export const ContactColumn = styled.div`
  li {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }
  }
`;
