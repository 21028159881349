import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const FooterBlock = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query {
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={data => {
      const parsedData = JSON.parse(data.buroBorkSite.field);

      return (
        <div
          dangerouslySetInnerHTML={{ __html: parsedData['3-footer'][locale] }}
        />
      );
    }}
  />
);

FooterBlock.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default FooterBlock;
