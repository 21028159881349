import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import MenuItems from 'components/menuItems';
import PropTypes from 'prop-types';
import Logo from 'icons/logo.svg';
import { Container, Column, Copyright } from './footer.css';
import Contact from './contact';
import FooterMenu from './footerMenu';
import LocaleMenu from './localeMenu.js';
import FooterBlock from './FooterBlock';

const Reseller = () => {
  if (process.env.GATSBY_RESELLER === 'BUKAZU') {
    return (
      <a href="https://bukazu.com" title="Boekingssyteem voor verhuurobjecten">
        <Logo width="120px" height="12px" />
      </a>
    );
  } else {
    return (
      <a href="https://burobork.nl" title="Website gemaakt door Buro Bork">
        Buro Bork
      </a>
    );
  }
};

const Footer = ({ locale }) => (
  <React.Fragment>
    <Container>
      <Column>
        <h3>
          <FormattedMessage id="contact" />
        </h3>
        <Contact />
      </Column>
      <Column>
        <h3>
          <FormattedMessage id="information" />
        </h3>
        <FooterMenu locale={locale} />
      </Column>
      <Column>
        <FooterBlock locale={locale} />
      </Column>
      <Column>
        <h3>
          <FormattedMessage id="sitemap" />
        </h3>
        <StaticQuery
          query={graphql`
            query {
              allBuroBorkMenu(filter: { name: { eq: "HoofdMenu" } }) {
                edges {
                  node {
                    id
                    field
                    name
                    locale
                  }
                }
              }
            }
          `}
          render={data => {
            let menu = data.allBuroBorkMenu.edges.find(
              edge => edge.node.locale === locale
            );

            return (
              <Container>
                <MenuItems items={menu.node.field} />
              </Container>
            );
          }}
        />
        <LocaleMenu locale={locale} />
      </Column>
    </Container>
    <Copyright>
      <div>
        Copyright
        <span>{new Date().getFullYear()}</span>
      </div>
      <div>
        Ontworpen en gerealiseerd door: <Reseller />
      </div>
    </Copyright>
  </React.Fragment>
);

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default Footer;
