import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MenuItems from 'components/menuItems';
import PropTypes from 'prop-types';
import { Container } from './footer.css';

const FooterMenu = ({ locale }) => (
  <StaticQuery
    query={graphql`
      query {
        allBuroBorkMenu(filter: { name: { eq: "FooterMenu" } }) {
          edges {
            node {
              id
              field
              name
              locale
            }
          }
        }
      }
    `}
    render={data => {
      let menu = data.allBuroBorkMenu.edges.find(
        edge => edge.node.locale === locale
      );
      if (menu) {
        return (
          <Container>
            <MenuItems items={menu.node.field} />
          </Container>
        );
      } else {
        return '';
      }
    }}
  />
);

FooterMenu.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default FooterMenu;
