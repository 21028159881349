import styled from 'styled-components';
import { pageWidth } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  border-bottom: 1px solid rbga(0, 0, 0, 0.25);
  max-height: 40px;
  padding: 4px;
  ${MEDIA.TABLET`
    display: none;
  `} .topnav-inner {
    max-width: ${pageWidth};
    margin: 0 auto;
    padding: 0 16px;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: flex-end;
    align-items: center;

    li {
      font-size: 1.4rem;
      padding: 8px;
      display: flex;
      a {
        display: flex;
        align-items: center;
        color: #55504b;
        text-decoration: none;
        svg {
          path {
            fill: ${props => props.theme.primaryColor};
          }
          margin: 0 8px 0 0;
        }
      }

      & + li {
        margin-left: 0.8rem;
        position: relative;
      }
      &.parent:hover {
        & > ul {
          display: flex;
        }
      }
      ul {
        position: relative;
        &.sub-menu {
          display: none;
          position: absolute;
          background: #fff;
          padding: 16px 32px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
          flex-direction: column;
          z-index: 9999;
          top: 32px;
          right: 0;
          li {
            margin: 0;
          }
        }
      }
    }
  }
`;
