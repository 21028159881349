import styled from 'styled-components';
// import { mainColor } from 'constants/theme';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.ul`
  display: flex;
  list-style: none;
  padding: 0 !important;
  justify-content: flex-end;
  align-items: center;
  li {
    display: flex;
    padding: 0 8px !important;
    align-items: center;
  }
  a {
    &:before {
      display: none;
    }
  }
`;
