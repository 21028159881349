import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: 'active' } : null;
};

const menuItems = ({ items }) => {
  items = JSON.parse(items);
  const navs = items.menu_items.map(function(item) {
    let val;

    const tarea_regex = /^(http|https)/;
    let url = '';
    if (tarea_regex.test(String(item.url).toLowerCase()) === true) {
      url = <a href={item.url}>{item.title}</a>;
    } else {
      url = (
        <Link to={item.url} getProps={isActive}>
          {item.title}
        </Link>
      );
    }
    val = <li key={item.title}>{url}</li>;

    return val;
  });

  return <ul>{navs}</ul>;
};

menuItems.propTypes = {
  items: PropTypes.string.isRequired,
};

export default menuItems;
