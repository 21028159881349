import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Container } from './topnav.css';
import { Link } from '@reach/router';
import ChevronDown from 'icons/chevron_down.svg';
import NlIcon from 'icons/locales/nl.svg';
import DeIcon from 'icons/locales/de.svg';

const localeIcon = {
  nl: <NlIcon height="16px" style={{ paddingRight: '8px' }} />,
  de: <DeIcon height="16px" style={{ paddingRight: '8px' }} />,
};

const TopNav = ({ data, locale }) => {
  let menuLocales = <div />;
  if (data.locales.length > 1) {
    menuLocales = (
      <li className="parent">
        {localeIcon[locale]}
        {locale.toUpperCase()}
        <ChevronDown height="10px" width="10px" fill="#55504b" />
        <ul className="sub-menu">
          <li>
            <NlIcon height="16px" style={{ paddingRight: '8px' }} />
            <Link to={'/'}>NL</Link>
          </li>
          <li>
            <DeIcon height="16px" style={{ paddingRight: '8px' }} />
            <Link to={'/de'}>DE</Link>
          </li>
        </ul>
      </li>
    );
  }

  return (
    <Container className="topnav">
      <div className="topnav-inner">
        <ul>
          <li>
            <a href={'mailto:' + data.email}>
              <svg
                width="27"
                height="16"
                viewBox="0 0 27 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.84615 0C1.48818 0 1.15737 0.103871 0.875 0.278846L12.3077 9.48077C12.9045 9.96114 13.5369 9.96105 14.1346 9.48077L25.5865 0.278846C25.3042 0.103871 24.9734 0 24.6154 0H1.84615ZM0.105769 1.23077C0.0380339 1.42331 0 1.62988 0 1.84615V14.1538C0 15.1766 0.823382 16 1.84615 16H24.6154C25.6382 16 26.4615 15.1766 26.4615 14.1538V1.84615C26.4615 1.62988 26.4235 1.42331 26.3558 1.23077L14.9038 10.4423C13.9117 11.2395 12.5305 11.2407 11.5385 10.4423L0.105769 1.23077Z"
                  fill="#55504B"
                />
              </svg>
              {data.email}
            </a>
          </li>
          {data.phone ? (
            <li>
              <a href={'phone:' + data.phone}>
                <svg
                  width="13"
                  height="18"
                  viewBox="0 0 13 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.71147 17.9462C11.2354 18.4299 12.8774 15.5054 12.8869 15.0152C12.9034 14.1793 10.3294 11.5923 9.9524 11.7947C9.35931 12.1131 7.61832 12.6979 7.31456 12.6129C6.19735 12.2997 3.29544 7.27344 3.58289 6.14914C3.66083 5.84374 5.03809 4.62827 5.61016 4.27398C5.97414 4.04865 5.02062 0.526014 4.28841 0.122119C3.85924 -0.114549 0.505488 -0.154938 0.162526 1.40679C-1.01964 6.789 4.45941 16.2789 9.71147 17.9462V17.9462Z"
                    fill="#55504B"
                  />
                </svg>
                {data.phone}
              </a>
            </li>
          ) : (
            ''
          )}
          {menuLocales}
        </ul>
      </div>
    </Container>
  );
};

TopNav.propTypes = {
  data: PropTypes.object.isRequired,
  locale: PropTypes.string,
};

const TopNavWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        buroBorkSite {
          id
          field
        }
      }
    `}
    render={data => (
      <TopNav data={JSON.parse(data.buroBorkSite.field)} {...props} />
    )}
  />
);

export default TopNavWithQuery;
